body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html, body, #root {
  height: 100%;
  overflow: auto;
}

.dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important;
}

.ui.modal.modal-fix {
  top: 0;
  margin-top: 50px;
}

.ui.styled.accordion .message .content {
  padding: 0;
}

.pdfs .active.virtual-list-item {
  background-color: #f3f4f5 !important;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important;
  box-shadow: 0 1px 2px 0 rgba(34,36,38,.15) !important;
  border-radius: .28571429rem !important;
  border: 1px solid rgba(34,36,38,.15) !important;
}

.ui.floating.floating-bottom {
  bottom: 0;
  top: initial;
}

.event.feed-flex-important {
  display: flex !important;
}

kbd {
  display: inline-block;
  margin: 0 .1em;
  padding: .1em .6em;
  font-family: Arial,"Helvetica Neue",Helvetica,sans-serif;
  font-size: 11px;
  line-height: 1.4;
  color: #242729;
  text-shadow: 0 1px 0 #FFF;
  background-color: #e1e3e5;
  border: 1px solid #adb3b9;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(12,13,14,0.2), 0 0 0 2px #FFF inset;
  white-space: nowrap;
}

.ui.items.inline-block>.item.visible.transition {
  display: inline-block!important;
}

.ui.items.inline>.item.visible.transition {
  display: inline!important;
}

.ui.items.flex>.item.visible.transition {
  display: flex!important;
}

div.layout-pane.layout-pane-primary {
  overflow-y: hidden;
}


.virtual-list-item {
  display: flex;
  margin: 1em 0;
  width: 100%;
  min-height: 0;
  background: 0 0;
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-transition: -webkit-box-shadow .1s ease;
  -webkit-transition: box-shadow .1s ease;
  transition: box-shadow .1s ease;
  z-index: '';
}

.virtual-list-item:first-child {
  margin-top: 0;
}

.virtual-list-item>.image+[class*="bottom aligned"].content {
  align-self: flex-end;
}
.virtual-list-item>.image+.content {
  min-width: 0;
  width: auto;
  display: block;
  margin-left: 0;
  align-self: top;
  padding-left: 1.5em;
}

.virtual-list-item>.content {
  display: block;
  flex: 1 1 auto;
  background: 0 0;
  margin: 0;
  padding: 0;
  box-shadow: none;
  font-size: 1em;
  border: none;
  border-radius: 0;
}